import React, { useState, useEffect } from "react";
import "./Timer.css";

export default function CountdownTimer() {
  const [timeLeft, setTimeLeft] = useState(59 * 60 + 59); // الوقت بالثواني (59 دقيقة و59 ثانية)
  const totalTime = 59 * 60 + 59; // الوقت الكلي بالثواني

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev > 0) {
          return prev - 1;
        } else {
          clearInterval(timer); // إيقاف المؤقت
          window.location.reload(); // تجديد الصفحة
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(timer); // تنظيف المؤقت عند إزالة المكون
  }, []);

  const formatTime = (time) => time.toString().padStart(2, "0");

  const days = 0; // ثابت
  const hours = 0; // ثابت
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  const progressWidth = (timeLeft / totalTime) * 100; // نسبة التقدم

  return (
    <div className="countdown-timer">
      {/* شريط التقدم */}

      {/* العدّاد */}
      <div className="progress-bar">
        <div className="progress" style={{ width: `${progressWidth}%` }}></div>
      </div>
      <div className="time-container">
        <div className="time-box">
          <span className="time-value">{formatTime(days)}</span>
          <span className="time-label">يوم</span>
        </div>
        <span className="colon">:</span>
        <div className="time-box">
          <span className="time-value">{formatTime(hours)}</span>
          <span className="time-label">ساعة</span>
        </div>
        <span className="colon">:</span>
        <div className="time-box">
          <span className="time-value">{formatTime(minutes)}</span>
          <span className="time-label">دقيقة</span>
        </div>
        <span className="colon">:</span>
        <div className="time-box">
          <span className="time-value">{formatTime(seconds)}</span>
          <span className="time-label">ثانية</span>
        </div>
      </div>
    </div>
  );
}
