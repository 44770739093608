import logo from "./logo.svg";
import "./App.css";
import { UsersProvider } from "./userContext";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import AppBar from "./Components/AppBar/AppBar";
import Footer from "./Components/Footer/Footer";
import ConfirmationPage from "./pages/ConfirmationPage/ConfirmationPage";

function App() {
  return (
    <div className="App">
      <UsersProvider>
        <AppBar />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/confirmation" element={<ConfirmationPage />} />
          </Routes>
        </BrowserRouter>
        <Footer />
      </UsersProvider>
    </div>
  );
}

export default App;
