import { createContext, useState } from "react";

const userContext = createContext();

export const UsersProvider = ({ children }) => {
  const [totalPrice, setTotalPrice] = useState(0);
  const [quantity, setQuantity] = useState(0);

  return (
    <userContext.Provider
      value={{ totalPrice, setTotalPrice, quantity, setQuantity }}
    >
      {children}
    </userContext.Provider>
  );
};

export default userContext;
