import React, { useState } from "react";
import "./AppBar.css";
import zainshop from "./zainshop.PNG";

const AppBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="appbar-container">
      <div className="appbar">
        <div style={{ width: "10px" }}></div>
        <div className="logo">
          <img src={zainshop} alt="Logo" />
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          {/* رمز القائمة للموبايل */}☰
        </div>
        <nav className={`menu ${isMenuOpen ? "open" : ""}`}>
          <ul>
            <li>
              <a onClick={toggleMenu} href="#الفيديو_التعليمي">
                الفيديو التعليمي
              </a>
            </li>
            <li>
              <a onClick={toggleMenu} href="#معلومات_عن_المنفاخ">
                معلومات عن المنفاخ
              </a>
            </li>
            <li>
              <a onClick={toggleMenu} href="#قدرة_المنفاخ">
                قدرة المنفاخ
              </a>
            </li>
            <li>
              <a onClick={toggleMenu} href="#تجربة_حقيقية">
                تجربة حقيقية
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default AppBar;
