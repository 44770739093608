import React, { useEffect, useState, useContext } from "react";
import { useLocation, Link } from "react-router-dom";
import userContext from "../../userContext";
import "./ConfirmationPage.css";

export default function ConfirmationPage() {
  const location = useLocation();
  const { formData } = location.state;
  const { totalPrice, setTotalPrice, quantity, setQuantity } =
    useContext(userContext); // استخدام تحديث البيانات

  const [showPopup, setShowPopup] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(false);
    }, 5000); // إخفاء البوب أب بعد 5 ثوانٍ
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="confirmation-page">
      <header className="confirmation-header">
        <h1>شكراً لثقتكم بنا!</h1>
      </header>
      <main className="confirmation-main">
        <h2>ملخص الطلبية</h2>
        <table className="order-summary-table">
          <tbody>
            <tr>
              <td className="label">الاسم:</td>
              <td className="value">{formData.name}</td>
            </tr>
            <tr>
              <td className="label">رقم الهاتف:</td>
              <td className="value">{formData.phone}</td>
            </tr>
            <tr>
              <td className="label">البلد:</td>
              <td className="value">{formData.country}</td>
            </tr>
            <tr>
              <td className="label">عدد القطع:</td>
              <td className="value">{quantity}</td>
            </tr>
            <tr>
              <td className="label">السعر الإجمالي:</td>
              <td className="value">{totalPrice} شيكل</td>
            </tr>
            <tr>
              <td className="label">طريقة الدفع:</td>
              <td className="value">الدفع عند الاستلام</td>
            </tr>
          </tbody>
        </table>
        <p className="thank-you-message">
          نقوم الآن بتغليف طلبيتكم بأفضل طريقة لأنكم تستحقون الأفضل. سيتم
          التواصل معكم قريباً لتأكيد الطلب!
        </p>
        {/* زر العودة إلى الصفحة الرئيسية */}
        <div className="back-to-home">
          <Link to="/" className="back-home-button">
            العودة إلى التسوّق
          </Link>
        </div>
      </main>
      {/* زر الواتساب */}
      <div className="whatsapp-button">
        <a
          href="https://wa.me/972528919198" // استبدل الرقم برقم صاحب المشروع
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-whatsapp"></i> {/* أيقونة واتساب */}
        </a>
        {showPopup && (
          <div className="whatsapp-popup">
            نحن متاحون من أجلك عبر واتس اب أيضًا
          </div>
        )}
      </div>
      <footer className="confirmation-footer">
        <p>© 2024 جميع الحقوق محفوظة</p>
      </footer>
    </div>
  );
}
